<template>
  <div>
    <h1>Processed</h1>
    <p>
      <a href="https://drive.google.com/drive/folders/1P8mJQ3g4cJFhhiKav1GFdOf-q4YasxP5?usp=sharing"
        target="_blank"
      >Drive folder</a>
    </p>
    <FileList
      :folderRef="processed"
      @on-confirm="onConfirm = $event"
    >
      <!-- :display-filter="item => item.metadata" -->
      <template #title="{ path }">
        {{ path.split('/')[1] }}
      </template>

      <template #actions="{ item, filename, meta, customMeta, fetchImage }">
        <!-- <div v-if="customMeta().original">
          <button v-if="!original(customMeta()).data"
            class="btn btn-outline-info btn-sm"
            @click="fetchImage(original(customMeta()), item)"
            :disabled="original(customMeta()).fetching || original(customMeta()).data"
          >
            <span v-if="original(customMeta()).fetching"
              class="spinner-border spinner-border-sm text-info"></span>
            <span v-else>Load original</span>
          </button>
        </div> -->
        <button
          class="btn btn-outline-success btn-sm"
          v-if="!item.confirmed && !customMeta()['google-drive-id']"
          @click="confirm({
            item,
            name: filename(),
            contentType: meta().contentType,
            customMeta: customMeta(),
            fetchImage,
          })"
          :disabled="(
            item.fetching || item.confirming || item.confirmed
            || customMeta()['google-drive-id']
          )"
        >
          <span v-if="item.confirming"
            class="spinner-border spinner-border-sm text-success"></span>
          <span v-else>
            Accept
          </span>
        </button>
        <!-- <button v-if="customMeta()['google-drive-id']"
          class="btn btn-outline-danger btn-sm"
          @click="retract(item)"
        >
          <span v-if="item.retracting"
            class="spinner-border spinner-border-sm text-danger"></span>
          <span v-else>Retract</span>
        </button> -->
        <!-- <div v-if="item.original && item.original.data" class="mt-2">
          <a :href="item.original.url" target="_blank"
            class="btn btn-secondary btn-sm position-absolute"
          >Open original</a>
          <img :src="item.original.data" class="w-100" />
        </div> -->
      </template>
    </FileList>
  </div>
</template>

<script>
import Vue from 'vue';
import { processed, originals } from '@/storage';
import FileList from './FileList.vue';

export default {
  data: () => ({
    processed,
    onConfirm: null,
  }),
  components: {
    FileList,
  },
  methods: {
    original({ original }) {
      return originals.child((original || '').split('/')[1]);
    },

    async confirm({ item, name, contentType, customMeta, fetchImage }) {
      await fetchImage(item);
      Vue.set(item, 'confirming', true);

      const onConfirm = this.onConfirm;

      this.pushToDrive({
        file: item.blob,
        contentType,
        metadata: Object.assign(
          { name, parents: [process.env.VUE_APP_CONFIRMED_FOLDER_ID] },
          customMeta
        ),
        onFinish({ id }) {
          Vue.set(item, 'confirming', false);
          Vue.set(item, 'confirmed', id);
          onConfirm(item, {
            // these are wrong, the id I have is for the upload, not the file
            'google-drive-id': id,
            'google-drive-url': `https://drive.google.com/file/d/${id}/view?usp=sharing`,
          });
        },
      });
    },
    retract(item) {
      // TODO: Destroy google drive file
      Vue.set(item, 'retracting', true);
      this.onConfirm(item, {
        'google-drive-id': null,
        'google-drive-url': null,
      }).then(() => {
        Vue.set(item, 'retracting', false);
        Vue.set(item, 'confirmed', false);
      });
    },
    pushToDrive({ file, contentType, metadata, onFinish }) {
      const fileMeta = JSON.stringify(metadata);

      // The drive.files.create() api is completely broken, idk.
      // It makes empty files named Untitled at the root of the user's drive.
      // Its behavior is indistinguishable from calling it without arguments.
      /* window.gapi.client.drive.files.create({
        fields: 'id',
        supportsAllDrives: true,
        uploadType: 'upload',
        resource: fileMeta,
        media: {
          mimeType: contentType,
          body: file,
        },
      }).then(({ status, statusText, result }) => {
        if (status !== 200) {
          console.error(status, statusText, result);
        } else {
          onFinish(result);
        }
      }).catch(console.error); */

      // Send resumable request, then follow up with payload request
      window.gapi.client.request({
        path: 'https://www.googleapis.com/upload/drive/v3/files',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Length': new Blob([fileMeta]).size,
          'X-Upload-Content-Type': contentType,
        },
        params: {
          uploadType: 'resumable',
          supportsAllDrives: true,
        },
        body: fileMeta,
      }).execute((error, response) => {
        if (error) {
          console.error(error);
          return;
        }
        // NOTE: This second request WILL "fail" because of cors, but it's only
        // the response content that is truncated.  That the request goes
        // through at all is what we care about.
        // We can't use `mode: 'no-cors'` because that stops us from using the
        // PUT method required by the drive api.
        const { gapiRequest: { data: { headers } } } = JSON.parse(response);
        const id = headers['x-guploader-uploadid'];
        fetch(new Request(headers.location, {
          method: 'PUT',
          headers: { 'Content-Length': file.size },
          body: file,
        })).catch(() => { onFinish({ id }) });
      });
    }
  },
}
</script>
