import Vue from 'vue';
import VueRouter from 'vue-router';
import Upload from './components/Upload.vue';
import Queue from './components/Queue.vue';
// import Proof from './components/Proof.vue';
import Processed from './components/Processed.vue';
// import Archive from './components/Archive.vue';
import Originals from './components/Originals.vue';

Vue.use(VueRouter);

export default new VueRouter({
  routes: [
    { path: '/', name: 'upload', component: Upload, props: true },
    { path: '/queue', name: 'queue', component: Queue, props: true },
    // { path: '/proof', name: 'proof', component: Proof, props: true },
    { path: '/processed', name: 'processed', component: Processed, props: true },
    // { path: '/archive', name: 'archive', component: Archive, props: true },
    { path: '/originals', name: 'originals', component: Originals, props: true },
  ],
});
