<template>
  <div id="app" class="bg-dark text-light">
    <div v-if="user">
      <nav class="navbar navbar-expand-sm navbar-dark bg-dark">
        <a class="navbar-brand" href="/">
          <img
            src="/img/icon.png"
            class="d-inline-block align-top rounded-circle mr-3"
            height="30"
            alt="Cards Logo"
          />
          <img
            src="/img/logo.png"
            class="d-inline-block align-top"
            height="30"
            alt="Mythulu Creation Cards"
          />
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-nav" aria-controls="main-nav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="main-nav">
          <ul class="navbar-nav mr-auto">
            <li :class="{'nav-item': true, 'active': $route.name === 'upload'}">
              <a href="#" class="nav-link"
                @click.prevent="$router.push({ name: 'upload' })"
              >Upload</a>
            </li>
            <li :class="{'nav-item': true, 'active': $route.name === 'queue'}">
              <a href="#" class="nav-link"
                @click.prevent="$router.push({ name: 'queue' })"
              >Queue</a>
            </li>
            <!-- <li :class="{'nav-item': true, 'active': $route.name === 'proof'}">
              <a href="#" class="nav-link"
                @click.prevent="$router.push({ name: 'proof' })"
              >Proof</a>
            </li> -->
            <li :class="{'nav-item': true, 'active': $route.name === 'processed'}">
              <a href="#" class="nav-link"
                @click.prevent="$router.push({ name: 'processed' })"
              >Processed</a>
            </li>
            <!-- <li :class="{'nav-item': true, 'active': $route.name === 'archive'}">
              <a href="#" class="nav-link"
                @click.prevent="$router.push({ name: 'archive' })"
              >Archive</a>
            </li> -->
            <li :class="{'nav-item': true, 'active': $route.name === 'originals'}">
              <a href="#" class="nav-link"
                @click.prevent="$router.push({ name: 'originals' })"
              >Originals</a>
            </li>
          </ul>
          <div class="nav-item dropdown ml-auto">
            <a class="nav-link dropdown-toggle" href="#"
              id="user-button"
              data-toggle="dropdown"
              @click.prevent
            >
              <img :src="user.providerData[0].photoURL" height="30" class="d-inline-block align-top rounded border border-primary mr-2" alt="User image">
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="#" @click.prevent="signOut">
                Log out
              </a>
            </div>
          </div>
        </div>
      </nav>

      <div class="container mt-2">
        <GitHubActions
          @get-instance-by-id="getInstance = $event"
          @get-instances="getInstances = $event"
          @dispatch-action="dispatchAction = $event"
        />
        <router-view
          :get-instance-by-id="getInstance"
          :get-instances="getInstances"
          :dispatch-action="dispatchAction"
        ></router-view>
      </div>
    </div>
    <div v-else class="text-center mt-5">
      <button class="btn btn-primary" @click="signIn">Log in</button>
    </div>
    <div style="min-height: 400px;"></div>
  </div>
</template>

<script>
import GitHubActions from './components/GitHubActions.vue';


export default {
  name: 'App',
  data: () => ({
    user: null,
    reloadQueue: null,
    dispatchAction: null,
    getInstance: null,
    getInstances: null,
  }),
  methods: {
    signIn() {
      window.firebase.auth()
        .signInWithPopup(new window.firebase.auth.GoogleAuthProvider())
        .catch(console.error)
        ;
    },
    signOut() {
      window.firebase.auth().signOut();
    },
  },
  created() {
    window.firebase.auth()
      .onAuthStateChanged((user) => {
        this.user = user;
      })
      ;
  },
  components: {
    // Vast,
    GitHubActions,
  },
}
</script>

<style>
  .container a {
    color: #5DADF2 !important;
  }
</style>
