// Each config requires an 'engine', which is set as firebase storage metadata
// for any uploaded file for that config.
export const configs = [
  {
    engine: 'Experimental',
    runtype: 'ssh',
    image: 'pytorch/pytorch:1.8.0-cuda11.1-cudnn8-runtime',
    onstart_cmd: [
      'git clone --depth=1 https://<TOKEN>@github.com/mythulu-software/card-art-ai.git',
      'cd card-art-ai',
      './run.sh',
    ].join('\n'),
    // price,
    // disk,
    // extra,
    // create_from,
  },
  {
    engine: 'Standard',
    runtype: 'ssh',
    image: 'pytorch/pytorch:1.8.0-cuda11.1-cudnn8-runtime',
    // price,
    // disk,
    // extra,
    // create_from,
  },
]
