<template>
  <div>
    <h1>Queued</h1>
    <FileList
      :folderRef="queue"
      @on-confirm="onModify = $event"
    >
      <template #header-extras="{ items }">
        <a href="#" @click.prevent="runEngine" class="ml-2">Run</a>
        <a href="#" @click.prevent="clearQueued(items)"
          class="ml-auto mr-3"
        >Clear reservations</a>
        <a href="#" @click.prevent="setEngine('Standard', items)"
        >Set all <code>Standard</code></a>
      </template>

      <template #actions="{ item, customMeta }">
        <button v-if="!item.deleted"
          class="btn btn-outline-danger btn-sm"
          @click="destroy(item)"
          :disabled="item.deleting || item.deleted"
        >
          <span v-if="item.deleting"
            class="spinner-border spinner-border-sm text-info"></span>
          <span v-else>Delete</span>
        </button>
        <button v-else :disabled="true">Deleted</button>
        <button v-if="customMeta().queued_by"
          class="ml-auto btn btn-outline-secondary btn-sm"
          @click="clearQueued([item])"
        >
          {{ customMeta().queued_by }}
        </button>
      </template>
    </FileList>
  </div>
</template>

<script>
import { queue } from '@/storage';
import { workflows } from '@/github';
import FileList from './FileList.vue';

export default {
  props: {
    getInstances: Function,
    dispatchAction: Function,
  },
  data: () => ({
    queue,
    onModify: null,
  }),
  methods: {
    setEngine(engine, items) {
      items.forEach((item) => {
        this.onModify(item, { engine });
      });
    },
    clearQueued(items) {
      items.forEach((item) => {
        this.onModify(item, { queued_by: null });
      });
    },
    async runEngine() {
      const { id } = this.getInstances()[0];
      return await this.dispatchAction(workflows.run, {
        instance_id: `${id}`,
      });
    },
    async destroy(item) {
      item.deleting = true;
      await item.delete().catch(console.error);
      item.deleting = false;
      item.deleted = true;
    },
  },
  components: {
    FileList,
  },
}
</script>
