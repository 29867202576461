<template>
  <div>
    <h1>
      Upload
      <form @submit.prevent="uploadAll(selectedConfig)"
        class="form-inline"
        style="float: right;"
      >
        <button v-if="files.length"
          type="submit"
          class="btn btn-primary btn-sm mr-2"
        >Upload {{ files.length }}</button>
        <input class="form-control form-control-sm mr-2 p-0"
          type="file"
          accept="image/*"
          multiple
          @change="addImages"
        />
        <select class="form-control form-control-sm" v-model="useConfig">
          <option v-for="config in configs" :key="config.engine"
            :value="config.engine"
          >
            {{ config.engine }}
          </option>
        </select>
      </form>
    </h1>

    <div class="row" style="min-height: 200px;">
      <div
        v-for="(info, i) in files"
        :key="i"
        class="col-2"
      >
        <UploadFile
          :i="i"
          :image-data="info.imageData"
          :completion="info.completion"
          :url="info.url"
          :name="info.name"
          :ext="info.ext"
          v-on:remove="dequeue"
          v-on:destroy="destroy"
          v-on:name="rename"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { queue } from '@/storage';
import { configs } from '@/vast-api';
import UploadFile from './UploadFile.vue';

export default {
  data: () => ({
    files: [],
    configs,
    useConfig: 'Standard',
  }),
  computed: {
    selectedConfig() {
      return this.configs.filter(config => config.engine === this.useConfig)[0];
    }
  },
  methods: {
    addImages(event) {
      this.files.push(...[...event.target.files].map(imageData => ({
        completion: 0,
        url: null,
        imageData,
        name: imageData.name.split('.').slice(0, -1).join('.'),
        ext: imageData.name.split('.').slice(-1)[0],
      })));
    },
    ref({ name, ext }) {
      return queue.child(`${name}.${ext}`);
    },
    async uploadAll(config) {
      const names = Object.assign({}, ...this.files.map(
        ({ url, name, ext }) => ({ [`${name}.${ext}`]: url })
      ));
      await Promise.all(
        this.files.map(info => this.upload(info, config))
      );
      this.$emit('batch-complete', names);
    },
    uploadOne(i, config) {
      this.upload(this.files[i], config);
    },
    async upload(info, { engine }) {
      const f = this.ref(info).put(info.imageData);
      return new Promise((resolve, reject) => {
        f.on('state_changed',
          (snapshot) => {
            info.completion = snapshot.bytesTransferred / snapshot.totalBytes;
          },
          (error) => {
            console.error(info.name, error.message, info);
            reject(error);
          },
          () => {
            info.completion = 1;
            f.snapshot.ref.updateMetadata({ customMetadata: { engine } })
              // .then(console.log)
              .catch(console.error)
              ;
            f.snapshot.ref.getDownloadURL().then((url) => {
              info.url = url;
            });
            this.files.splice(this.files.indexOf(info), 1);
            resolve(info);
          },
        );
      })
    },
    rename(i, name) {
      this.files[i].name = name;
    },
    dequeue(i) {
      this.files.splice(i, 1);
    },
    destroy(i) {
      this.files[i].busy = true;
      this.ref(this.files[i]).delete()
        .then(() => this.dequeue(i))
        .catch(e => this.files[i].error = e)
        ;
    },
  },
  components: {
    UploadFile,
  },
}
</script>
