<template>
  <div>
    <p class="text-light">
      {{ items.length }} item{{ items.length === 1 ? '' : 's' }}
      <a href="#" @click.prevent="reload">
        <span v-show="loaded">Reload</span>
        <div v-show="!loaded" class="spinner-border spinner-border-sm text-primary"></div>
      </a>
    </p>
    <div v-show="loaded && !items.length">
      No files.
    </div>
    <div v-for="(group, engine) in groups" :key="engine">
      <h5 class="card-title mb-0 d-flex align-items-left">
        {{ engine }}
        <span class="badge badge-primary ml-2"
        >{{ group.length }}</span>
        <!-- <span class="ml-auto"></span> -->
        <slot name="header-extras" :items="items"></slot>
      </h5>
      <div class="row">
        <div v-for="(item, i) in items" :key="item.toString()"
          :class="{
            'col-3 my-2': true,
            // 'd-none': customMeta('google-drive-id'),
          }"
        >
          <div :class="{
            card: true,
            'border-success': customMeta(i)['google-drive-id'],
            'border-light': !customMeta(i)['google-drive-id'],
          }">
            <div class="card-header px-1 py-2 bg-dark">
              <h6>{{ name(i) }}</h6>
              <div class="text-light d-flex align-items-left">
                <span>{{ size(i) }} MB</span>
                <span class="ml-auto"></span>
                <a v-if="!item.data" href="#"
                  @click.prevent="fetchImage(item)"
                  class="mr-1"
                  :disabled="item.fetching"
                >
                  <span v-if="item.fetching"
                    class="spinner-border spinner-border-sm text-info"
                  ></span>
                  <span v-else>[Load]</span>
                </a>

                <a href="#" @click.prevent="toggleMeta(i)">
                  <span v-show="!showMeta[i]">[Tags]</span>
                  <span v-show="showMeta[i]">[Hide]</span>
                </a>
              </div>
            </div>
            <div class="bg-dark p-1 d-flex justify-content-between">
              <slot name="actions"
                :i="i"
                :item="item"
                :data="item.data"
                :fetching="item.fetching"
                :fetchImage="fetchImage"
                :filename="() => name(i)"
                :meta="() => meta(i)"
                :customMeta="() => customMeta(i)"
              ></slot>
            </div>
            <dl v-show="showMeta[i]" class="p-2 mb-0 bg-dark">
              <!-- <div class="row no-gutters">
                <div class="col-4">
                  <input :ref="`${i}-new-name`" type="text"
                    placeholder="Name"
                    class="w-100"
                  />
                </div>
                <div class="col-4">
                  <input :ref="`${i}-new-value`" type="text"
                    placeholder="Value"
                    class="w-100"
                  />
                </div>
                <div class="col">
                  <button class="btn btn-outline-secondary btn-block"
                    @click="setNewValue(item, $refs[`${i}-new-name`].value, $refs[`${i}-new-value`].value)"
                  >+</button>
                </div>
              </div> -->
              <div v-for="(v, k) in customMeta(i)" :key="k"
                class="d-flex align-items-left border-top border-secondary"
              >
                <dt>{{ k }}</dt>
                <dd class="ml-auto" :title="v">
                  <code class="text-right" style="float: right;"
                    v-if="typeof v === 'string'"
                  >
                    <a v-if="v.startsWith('http')" :href="v" target="_blank">[Link]</a>
                    <a href="#" v-else-if="v.length > 40" :title="v" style="cursor: pointer"
                      @click.prevent="copyValue($refs[`${i}-${k}`])"
                    >[Copy]</a>
                    <span v-else
                    >{{ v }}</span>
                      <!-- @dblclick="promptNewValue(item, k, v)" -->
                    <input :ref="`${i}-${k}`" type="text" :value="v"
                      tabindex="0"
                      style="z-index: -1; position: absolute;"
                    />
                  </code>
                </dd>
              </div>
            </dl>
            <div class="card-body p-0 bg-dark">
              <div v-if="item.data">
                <a :href="item.url" target="_blank"
                  class="btn btn-primary btn-sm position-absolute"
                >Open</a>
                <img :src="item.data" class="w-100" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      v-show="nextPageToken"
      class="btn btn-secondary"
      @click="getPage"
    >Load more</button>
  </div>
</template>

<script>
import Vue from 'vue';

const CACHE = caches.open('firestore');
const PAGE_SIZE = 50;

export default {
  props: {
    folderRef: Object,
  },
  data: () => ({
    loaded: false,
    items: [],
    flagged: {},
    nextPageToken: undefined,
    groups: {},
    metadata: {},
    showMeta: {},
  }),
  methods: {
    copyValue([ target ]) {
      console.log(target);
      target.select();
      target.setSelectionRange(0, 99999);
      document.execCommand('copy');
    },
    name(i) {
      return this.items[i].toString().split('/').slice(-1)[0];
    },
    meta(i) {
      return this.metadata[i] || {};
    },
    customMeta(i) {
      return this.meta(i).customMetadata || {};
    },
    toggleMeta(i) {
      Vue.set(this.showMeta, i, !this.showMeta[i]);
    },
    size(i) {
      return Math.round(100 * (this.meta(i).size || 0) / 1000 / 1000) / 100;
    },
    virtualCacheUrl(item) {
      return item.toString().replace('gs:/', '');
    },
    async fetchImage(item, parent=null) {
      if (item.fetching) {
        return;
      }

      Vue.set(item, 'fetching', true);

      const request = new Request(this.virtualCacheUrl(item));
      await CACHE.then(async (cache) => {
        if (!await cache.match(request)) {
          await cache.put(request, (await fetch(await item.getDownloadURL())));
        }
        const blob = await (await cache.match(request)).blob();
        Vue.set(item, 'data', URL.createObjectURL(blob));
        Vue.set(item, 'url', await item.getDownloadURL());
        Vue.set(item, 'blob', blob);
        if (parent) {
          parent.original = item;
        }
      }).catch(console.error);
      Vue.set(item, 'fetching', false)
    },
    fetchMetadata(item) {
      if (this.metadata[item.toString()]) {
        return new Promise(() => undefined);
      }
      return item.getMetadata()
        .then((metadata) => {
          Vue.set(this.metadata, this.items.indexOf(item), metadata);
          const { engine } = metadata.customMetadata || {};
          if (!this.groups[engine]) {
            Vue.set(this.groups, engine, []);
          }
          this.groups[engine].push(item);
        })
        ;
    },
    async setNewValue(item, tagName, tagValue) {
      await item.updateMetadata({ customMetadata: { [tagName]: tagValue } });
      this.fetchMetadata(item);
    },
    async promptNewValue(item, tagName, tagValue) {
      const value = prompt(tagName, tagValue);
      this.setNewValue(item, tagName, value);
    },
    async getPage() {
      const { items, nextPageToken } = await this.folderRef.list({
        maxResults: PAGE_SIZE,
        pageToken: this.nextPageToken,
      });
      this.appendItems(items);
      this.nextPageToken = nextPageToken;
      this.loaded = true;
    },
    appendItems(items) {
      this.items.push(...items);
      items.map(this.fetchMetadata);
      CACHE.then((cache) => {
        items.forEach(async (item) => {
          const response = await cache.match(this.virtualCacheUrl(item));
          if (response) {
            const blob = await response.blob();
            Vue.set(item, 'data', URL.createObjectURL(blob));
          }
        });
      });
    },
    reload() {
      this.loaded = false;
      this.items.length = 0;
      Vue.set(this, 'groups', {});
      this.nextPageToken = undefined;
      this.getPage();
    },
    destroy(i) {
      this.folderRef.child(this.name(i)).delete()
        .then(() => this.items.splice(i, 1))
        .catch(e => this.files[i].error = e)
    },
  },
  created() {
    this.getPage();

    this.$emit('on-reload', (infos) => {
      this.reload();
      this.flagged = infos;
    });
    this.$emit('on-confirm', async (item, metadata) => {
      return item.updateMetadata({ customMetadata: metadata })
        .then((fullMetadata) => {
          const i = this.items.indexOf(item);
          Vue.set(this.metadata[i], 'customMetadata', Object.assign(
            {},
            fullMetadata.customMetadata || {},
            metadata
          ));
        })
        ;
    });
  }
}
</script>
