<template>
  <div class="mb-5">
    <nav class="nav nav-tabs" role="tablist">
      <li class="nav-item"
        :aria-selected="activeTab === 'offers'"
        href="#offers"
        data-toggle="tab"
        role="tab"
        aria-controls="offers"
      >
        <a href="#" @click="activeTab = 'offers'" :class="{
          'nav-link mx-1': true,
          'active': activeTab === 'offers',
        }">
          <span v-show="loadingOffers"
            class="spinner-grow spinner-grow-sm text-info mr-2"
          />Offers
        </a>
      </li>
      <li class="nav-item"
        :aria-selected="activeTab === 'instances'"
        href="#instances"
        data-toggle="tab"
        role="tab"
        aria-controls="instances"
      >
        <a href="#" @click="activeTab = 'instances'" :class="{
          'nav-link mx-1': true,
          'active': activeTab === 'instances',
        }">
          <span v-show="loadingInstances"
            class="spinner-grow spinner-grow-sm text-info mr-2"
          />Instances
        </a>
      </li>
      <li class="nav-item nav-link">
        <a href="#" @click.prevent="refreshInstancesArtifact">↻</a>
      </li>
      <li class="nav-item ml-auto">
        <input v-model="apiToken" class="form-control input-sm"
          placeholder="Paste your GitHub Token"
          @change="refreshLists"
          style="background: transparent; color: white; width: 300px;"
        />
      </li>
      <li class="nav-item"
        :aria-selected="activeTab === 'action-history'"
        href="#action-history"
        data-toggle="tab"
        role="tab"
        aria-controls="action-history"
      >
        <a href="#" @click="activeTab = 'action-history'" :class="{
          'nav-link mx-1': true,
          'active': activeTab === 'action-history',
        }">
          <span v-show="loadingRuns"
            class="spinner-grow spinner-grow-sm text-info mr-2"
          />Action History
        </a>
      </li>
    </nav>

    <div class="tab-content">
      <div id="action-history"
        :class="{
          'tab-pane': true,
          'active': activeTab === 'action-history',
        }"
        role="tabpanel"
        aria-labelledby="action-history"
      >
        <div v-show="loadingRuns" class="text-center">
          <span class="spinner-grow text-info"></span>
        </div>
        <ol v-show="!loadingRuns" class="list-group">
          <li v-for="run in runs" :key="run.id"
            class="list-group-item d-flex align-items-left bg-dark"
          >
            <span v-show="run.status !== 'completed'"
              class="spinner-border spinner-border-sm text-info"
            ></span>
            <strong>{{ run.name }}</strong>
            <span
              :class="{
                'badge ml-2': true,
                'bg-info': run.status !== 'completed',
                'bg-success': run.conclusion === 'success',
                'bg-danger': run.conclusion !== 'success',
              }"
              style="line-height: inherit !important"
            >{{ run.status }}</span>
            <span class="ml-auto mx-2 timeago" :datetime="run.created_at"
            ></span>
            <!-- <span><a :href="run.logs_url" target="_blank">View logs</a></span> -->
          </li>
        </ol>
      </div>
      <div id="instances"
        :class="{
          'tab-pane': true,
          'active': activeTab === 'instances',
        }"
        role="tabpanel"
        aria-labelledby="instances"
      >
        <div v-show="loadingInstances === true" class="text-center">
          <span class="spinner-grow text-info"></span>
        </div>
        <div v-show="loadingInstances === null" class="text-center">
          <span class="spinner-grow text-warning"></span>
        </div>
        <VastTable
          v-show="loadingInstances === false"
          :rows="instances"
          :fields="[
            // ['logo', 'Logo', x => `https://vast.ai${x}`],
            ['id', 'ID'],
            ['label', 'Label'],
            // ['machine_id', 'Machine'],
            ['image_uuid', 'Image'],
            ['gpu_name', 'GPU'],
            ['cpu_name', 'CPU'],
            ['gpu_ram', 'GPU RAM', x => `${(Math.round(x / 1000) )} GB`],
            ['cpu_ram', 'CPU RAM', x => `${(Math.round(x / 1000) )} GB`],
            // ['num_gpus', 'Num', x => `⨉ ${x}`],
            // ['cpu_cores_effective', 'vCPUs', x => Math.round(x * 10) / 10],
            // ['disk_space', 'Storage', x => `${Math.round(x)} GB`],
            // ['ssh_host', 'SSH Addr'],
            // ['ssh_port', 'SSH Port'],
            // ['duration', 'Max Days', x => (Math.round(x * 10) / 10) / (24.0*60.0*60.0)],
            ['dph_total', '$/hr', x => Math.round(x * 1000) / 1000],
            ['reliability2', 'R', x => 100 * Math.round(x * 1000) / 1000],
            ['gpu_temp', 'GPU Temp.', x => `${x}˚C`],
            ['gpu_util', 'Util. %', x => `${Math.round(x * 10) / 10}%`],
            // ['intended_status', 'Status_'],
            // ['actual_status', 'Status'],
          ]"
        >
          <template #actions="{ row: instance }">
            <div v-if="instance.actual_status === instance.intended_status">
              <button v-show="instance.actual_status === 'stopped'"
                @click="start(instance.id)"
                class="btn btn-outline-success btn-sm btn-block"
              >Start</button>
              <button v-show="instance.actual_status === 'running'"
                @click="stop(instance.id)"
                class="btn btn-outline-danger btn-sm btn-block"
              >Stop</button>
              <button
                @click="destroy(instance.id)"
                class="btn btn-outline-danger btn-sm btn-block"
              >Destroy</button>
              <button
                @click="configure(instance.id, 'Standard')"
                class="btn btn-outline-danger btn-sm btn-block"
              >Configure</button>
            </div>
            <div v-else>
              <em>*{{ instance.intended_status }}</em>
            </div>
          </template>
        </VastTable>
      </div>
      <div id="offers"
        :class="{
          'tab-pane': true,
          'active': activeTab === 'offers',
        }"
        role="tabpanel"
        aria-labelledby="offers"
      >
        <div v-show="loadingOffers === true" class="text-center">
          <span class="spinner-grow text-info"></span>
        </div>
        <div v-show="loadingOffers === null" class="text-center">
          <span class="spinner-grow text-warning"></span>
        </div>
        <VastTable
          v-show="loadingOffers === false"
          :rows="offers"
          :fields="[
            ['id', 'ID'],
            // ['machine_id', 'machine_id'],
            ['cuda_max_good', 'CUDA'],
            ['gpu_name', 'GPU'],
            ['num_gpus', 'Num', x => `⨉ ${x}`],
            ['gpu_ram', 'GPU RAM', x => `${Math.round(x / 1000)} GB`],
            ['cpu_cores_effective', 'vCPUs', x => `${Math.round(x * 10) / 10}`],
            ['cpu_ram', 'CPU RAM', x => `${Math.round(x / 1000)} GB`],
            // ['dlperf', 'DLPerf', x => Math.round(x * 10) / 10],
            // ['dlperf_per_dphtotal', 'DLP/$', x => Math.round(x * 10) / 10],
            ['disk_space', 'Storage', x => `💽 ${Math.round(x)} GB`],
            ['inet_up', 'Up', x => `↑ ${Math.round(x * 10) / 10} Mbps`],
            ['inet_down', 'Down', x => `↓ ${Math.round(x * 10) / 10} Mbps`],
            ['dph_total', 'Cost', x => `$${Math.round(x * 1000) / 1000}/hr`],
            ['reliability2', 'R', x => 100 * Math.round(x * 10) / 10],
            // ['pcie_bw', 'PCIE_BW', x => Math.round(x * 10) / 10],
            ['duration', 'Max Days', x => Math.round(10 * (Math.round(x * 10) / 10) / (24.0*60.0*60.0)) / 10],
          ]"
        >
          <template #actions="{ row: offer }">
            <div v-if="offer.actual_status === offer.intended_status">
              <button
                @click="rent(offer.id)"
                class="btn btn-outline-danger btn-sm btn-block"
              >Rent</button>
            </div>
            <div v-else>
              <em>{{ offer.intended_status }}</em>
            </div>
          </template>
        </VastTable>
      </div>
    </div>
  </div>
</template>

<script>
import { workflows, run, getRuns, getInstances } from '@/github';
import VastTable from './VastTable.vue';

const REFRESH_SECONDS = 30;

export default {
  data: () => ({
    apiToken: process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_GITHUB_TOKEN : null,
    activeTab: 'instances',
    loadingRuns: true,
    loadingInstances: true,
    loadingInstancesRun: false,
    loadingOffers: false,
    instancesRunId: null,
    refresh_seconds: REFRESH_SECONDS,
    runs: [],
    instances: [],
    offers: [],
  }),
  methods: {
    async dispatch(workflowId, inputs) {
      return await run(this.apiToken, workflowId, inputs);
    },
    async listConfiguationHistory() {
      this.loadingRuns = true;
      this.runs.length = 0;
      this.runs.push(...await getRuns(this.apiToken));

      // Discover recent instances.json
      if (!this.loadingInstancesRun && !this.instancesRunId) {
        const [ { id, conclusion }={} ] = this.runs.filter(
          ({ name }) => (name === "List Vast.ai Instances")
        );
        if (conclusion === 'success') {
          this.instancesRunId = id;
        }
      }

      this.loadingRuns = false;
    },
    async listInstances() {
      if (this.loadingRuns) {
        setTimeout(this.listInstances, 5000);
      } else if (!this.instancesRunId) {
        if (!this.loadingInstancesRun) {
          this.refreshInstancesArtifact();
        }
        setTimeout(this.listInstances, 5000);
      } else if (this.instancesRunId) {
        this.loadingInstancesRun = false;
        this.loadInstances();
      }
    },
    async refreshInstancesArtifact() {
      this.loadingInstances = true;
      this.loadingInstancesRun = true;
      await run(this.apiToken, workflows.list);
      setTimeout(this.refreshLists, 15 * 1000);
    },
    async loadInstances() {
      this.loadingInstances = true;
      this.instances.length = 0;
      this.instances.push(...await getInstances(this.apiToken, this.instancesRunId));
      this.loadingInstances = false;
    },
    async refreshLists() {
      if (!this.$timeout) {
        await this.listConfiguationHistory();
        await this.listInstances();
        this.$timeout = setTimeout(
          this.refreshLists,
          this.refresh_interval * 10000
        );
      }
    },
    getInstanceById(instance_id) {
      return this.instances.filter(({ id }) => id == instance_id)[0];
    },
    async find(params) {
      return await run(this.apiToken, workflows.find, params);
    },
    async rent(offer_id) {
      return await run(this.apiToken, workflows.rent, { offer_id });
    },
    async configure(instance_id, engine,/* , engine_opts, batch_size */) {
      // const { ssh_host, ssh_port } = this.getInstanceById(instance_id);
      return await run(this.apiToken, workflows.configure, {
        instance_id: `${instance_id}`,
        engine,
        // ssh_host,
        // ssh_port: `${ssh_port}`,  // github requires strings apparently
        // engine_opts,
        // batch_size,
      });
    },
    async destroy(instance_id) {
      await run(this.apiToken, workflows.destroy, { instance_id: `${instance_id}` });
      await this.refreshInstancesArtifact();
    },
    async start(instance_id) {
      await run(this.apiToken, workflows.start, { instance_id: `${instance_id}` });
      await this.refreshInstancesArtifact();
    },
    async stop(instance_id) {
      await run(this.apiToken, workflows.stop, { instance_id: `${instance_id}` });
      await this.refreshInstancesArtifact();
    },
  },
  components: {
    VastTable,
  },
  created() {
    this.refreshLists();
    setTimeout(
      () => window.timeago.render(
        window.document.querySelectorAll('.timeago')),
      0);
    this.$emit('get-instance-by-id',
      (id) => this.getInstanceById(id)
    );

    // We need to set the instance label to the configured engine before this
    // will work as expected.
    // this.$emit('get-instances-by-engine',
    //   (engine) => this.getInstancesByEngine(id)
    // );
    // Workaround for all instances
    this.$emit('get-instances', () => this.instances);
    this.$emit('dispatch-action', this.dispatch);
  },
}
</script>
