var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Processed")]),_vm._m(0),_c('FileList',{attrs:{"folderRef":_vm.processed},on:{"on-confirm":function($event){_vm.onConfirm = $event}},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var path = ref.path;
return [_vm._v(" "+_vm._s(path.split('/')[1])+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
var filename = ref.filename;
var meta = ref.meta;
var customMeta = ref.customMeta;
var fetchImage = ref.fetchImage;
return [(!item.confirmed && !customMeta()['google-drive-id'])?_c('button',{staticClass:"btn btn-outline-success btn-sm",attrs:{"disabled":(
          item.fetching || item.confirming || item.confirmed
          || customMeta()['google-drive-id']
        )},on:{"click":function($event){_vm.confirm({
          item: item,
          name: filename(),
          contentType: meta().contentType,
          customMeta: customMeta(),
          fetchImage: fetchImage,
        })}}},[(item.confirming)?_c('span',{staticClass:"spinner-border spinner-border-sm text-success"}):_c('span',[_vm._v(" Accept ")])]):_vm._e()]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('a',{attrs:{"href":"https://drive.google.com/drive/folders/1P8mJQ3g4cJFhhiKav1GFdOf-q4YasxP5?usp=sharing","target":"_blank"}},[_vm._v("Drive folder")])])}]

export { render, staticRenderFns }