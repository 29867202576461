<template>
  <div>
    <h1>Originals</h1>
    <FileList
      :folderRef="originals"
      :allow-delete="true"
      :allow-download="true"
      :show-image="true"
    />
  </div>
</template>

<script>
import { originals } from '@/storage';
import FileList from './FileList.vue';

export default {
  data: () => ({
    originals,
  }),
  components: {
    FileList,
  },
}
</script>
