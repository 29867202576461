<template>
  <table class="table table-striped text-light border border-top-0">
    <thead>
      <tr>
        <th v-for="field in fields" :key="field[0]"
          v-show="hidden.indexOf(field[0]) === -1"
          :data-field="field[0]"
        >{{ field[1] }}</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody v-if="rows.length">
      <tr v-for="row in rows" :key="row.id">
        <td v-for="field in fields" :key="field[0]"
          class="small"
          :data-field="field[0]"
          v-show="hidden.indexOf(field[0]) === -1"
        >{{ format(row[field[0]], field[2]) }}</td>
        <th class="actions">
          <slot name="actions" v-bind:row="row"></slot>
        </th>
      </tr>
    </tbody>
    <tbody v-else>
      <slot name="empty">
        <tr v-if="error">
          <td :colspan="fields.length + 1" class="text-danger text-center">
            {{ error }}
          </td>
        </tr>
        <tr v-else>
          <td :colspan="fields.length + 1" class="text-muted text-center">
            <em>No items to display.</em>
          </td>
        </tr>
      </slot>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    fields: Array,
    rows: Array,
    hidden: { type: Array, optional: true, default: () => [] },
    error: { type: undefined, optional: true, default: null },
  },
  methods: {
    format: (v, f) => (f || (x => x))(v),
  },
}
</script>
