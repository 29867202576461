var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-5"},[_c('nav',{staticClass:"nav nav-tabs",attrs:{"role":"tablist"}},[_c('li',{staticClass:"nav-item",attrs:{"aria-selected":_vm.activeTab === 'offers',"href":"#offers","data-toggle":"tab","role":"tab","aria-controls":"offers"}},[_c('a',{class:{
        'nav-link mx-1': true,
        'active': _vm.activeTab === 'offers',
      },attrs:{"href":"#"},on:{"click":function($event){_vm.activeTab = 'offers'}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingOffers),expression:"loadingOffers"}],staticClass:"spinner-grow spinner-grow-sm text-info mr-2"}),_vm._v("Offers ")])]),_c('li',{staticClass:"nav-item",attrs:{"aria-selected":_vm.activeTab === 'instances',"href":"#instances","data-toggle":"tab","role":"tab","aria-controls":"instances"}},[_c('a',{class:{
        'nav-link mx-1': true,
        'active': _vm.activeTab === 'instances',
      },attrs:{"href":"#"},on:{"click":function($event){_vm.activeTab = 'instances'}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingInstances),expression:"loadingInstances"}],staticClass:"spinner-grow spinner-grow-sm text-info mr-2"}),_vm._v("Instances ")])]),_c('li',{staticClass:"nav-item nav-link"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.refreshInstancesArtifact.apply(null, arguments)}}},[_vm._v("↻")])]),_c('li',{staticClass:"nav-item ml-auto"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.apiToken),expression:"apiToken"}],staticClass:"form-control input-sm",staticStyle:{"background":"transparent","color":"white","width":"300px"},attrs:{"placeholder":"Paste your GitHub Token"},domProps:{"value":(_vm.apiToken)},on:{"change":_vm.refreshLists,"input":function($event){if($event.target.composing){ return; }_vm.apiToken=$event.target.value}}})]),_c('li',{staticClass:"nav-item",attrs:{"aria-selected":_vm.activeTab === 'action-history',"href":"#action-history","data-toggle":"tab","role":"tab","aria-controls":"action-history"}},[_c('a',{class:{
        'nav-link mx-1': true,
        'active': _vm.activeTab === 'action-history',
      },attrs:{"href":"#"},on:{"click":function($event){_vm.activeTab = 'action-history'}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingRuns),expression:"loadingRuns"}],staticClass:"spinner-grow spinner-grow-sm text-info mr-2"}),_vm._v("Action History ")])])]),_c('div',{staticClass:"tab-content"},[_c('div',{class:{
        'tab-pane': true,
        'active': _vm.activeTab === 'action-history',
      },attrs:{"id":"action-history","role":"tabpanel","aria-labelledby":"action-history"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingRuns),expression:"loadingRuns"}],staticClass:"text-center"},[_c('span',{staticClass:"spinner-grow text-info"})]),_c('ol',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingRuns),expression:"!loadingRuns"}],staticClass:"list-group"},_vm._l((_vm.runs),function(run){return _c('li',{key:run.id,staticClass:"list-group-item d-flex align-items-left bg-dark"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(run.status !== 'completed'),expression:"run.status !== 'completed'"}],staticClass:"spinner-border spinner-border-sm text-info"}),_c('strong',[_vm._v(_vm._s(run.name))]),_c('span',{class:{
              'badge ml-2': true,
              'bg-info': run.status !== 'completed',
              'bg-success': run.conclusion === 'success',
              'bg-danger': run.conclusion !== 'success',
            },staticStyle:{"line-height":"inherit !important"}},[_vm._v(_vm._s(run.status))]),_c('span',{staticClass:"ml-auto mx-2 timeago",attrs:{"datetime":run.created_at}})])}),0)]),_c('div',{class:{
        'tab-pane': true,
        'active': _vm.activeTab === 'instances',
      },attrs:{"id":"instances","role":"tabpanel","aria-labelledby":"instances"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingInstances === true),expression:"loadingInstances === true"}],staticClass:"text-center"},[_c('span',{staticClass:"spinner-grow text-info"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingInstances === null),expression:"loadingInstances === null"}],staticClass:"text-center"},[_c('span',{staticClass:"spinner-grow text-warning"})]),_c('VastTable',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingInstances === false),expression:"loadingInstances === false"}],attrs:{"rows":_vm.instances,"fields":[
          // ['logo', 'Logo', x => `https://vast.ai${x}`],
          ['id', 'ID'],
          ['label', 'Label'],
          // ['machine_id', 'Machine'],
          ['image_uuid', 'Image'],
          ['gpu_name', 'GPU'],
          ['cpu_name', 'CPU'],
          ['gpu_ram', 'GPU RAM', function (x) { return (((Math.round(x / 1000) )) + " GB"); }],
          ['cpu_ram', 'CPU RAM', function (x) { return (((Math.round(x / 1000) )) + " GB"); }],
          // ['num_gpus', 'Num', x => `⨉ ${x}`],
          // ['cpu_cores_effective', 'vCPUs', x => Math.round(x * 10) / 10],
          // ['disk_space', 'Storage', x => `${Math.round(x)} GB`],
          // ['ssh_host', 'SSH Addr'],
          // ['ssh_port', 'SSH Port'],
          // ['duration', 'Max Days', x => (Math.round(x * 10) / 10) / (24.0*60.0*60.0)],
          ['dph_total', '$/hr', function (x) { return Math.round(x * 1000) / 1000; }],
          ['reliability2', 'R', function (x) { return 100 * Math.round(x * 1000) / 1000; }],
          ['gpu_temp', 'GPU Temp.', function (x) { return (x + "˚C"); }],
          ['gpu_util', 'Util. %', function (x) { return ((Math.round(x * 10) / 10) + "%"); }] ]},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
        var instance = ref.row;
return [(instance.actual_status === instance.intended_status)?_c('div',[_c('button',{directives:[{name:"show",rawName:"v-show",value:(instance.actual_status === 'stopped'),expression:"instance.actual_status === 'stopped'"}],staticClass:"btn btn-outline-success btn-sm btn-block",on:{"click":function($event){return _vm.start(instance.id)}}},[_vm._v("Start")]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(instance.actual_status === 'running'),expression:"instance.actual_status === 'running'"}],staticClass:"btn btn-outline-danger btn-sm btn-block",on:{"click":function($event){return _vm.stop(instance.id)}}},[_vm._v("Stop")]),_c('button',{staticClass:"btn btn-outline-danger btn-sm btn-block",on:{"click":function($event){return _vm.destroy(instance.id)}}},[_vm._v("Destroy")]),_c('button',{staticClass:"btn btn-outline-danger btn-sm btn-block",on:{"click":function($event){return _vm.configure(instance.id, 'Standard')}}},[_vm._v("Configure")])]):_c('div',[_c('em',[_vm._v("*"+_vm._s(instance.intended_status))])])]}}])})],1),_c('div',{class:{
        'tab-pane': true,
        'active': _vm.activeTab === 'offers',
      },attrs:{"id":"offers","role":"tabpanel","aria-labelledby":"offers"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingOffers === true),expression:"loadingOffers === true"}],staticClass:"text-center"},[_c('span',{staticClass:"spinner-grow text-info"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingOffers === null),expression:"loadingOffers === null"}],staticClass:"text-center"},[_c('span',{staticClass:"spinner-grow text-warning"})]),_c('VastTable',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingOffers === false),expression:"loadingOffers === false"}],attrs:{"rows":_vm.offers,"fields":[
          ['id', 'ID'],
          // ['machine_id', 'machine_id'],
          ['cuda_max_good', 'CUDA'],
          ['gpu_name', 'GPU'],
          ['num_gpus', 'Num', function (x) { return ("⨉ " + x); }],
          ['gpu_ram', 'GPU RAM', function (x) { return ((Math.round(x / 1000)) + " GB"); }],
          ['cpu_cores_effective', 'vCPUs', function (x) { return ("" + (Math.round(x * 10) / 10)); }],
          ['cpu_ram', 'CPU RAM', function (x) { return ((Math.round(x / 1000)) + " GB"); }],
          // ['dlperf', 'DLPerf', x => Math.round(x * 10) / 10],
          // ['dlperf_per_dphtotal', 'DLP/$', x => Math.round(x * 10) / 10],
          ['disk_space', 'Storage', function (x) { return ("💽 " + (Math.round(x)) + " GB"); }],
          ['inet_up', 'Up', function (x) { return ("↑ " + (Math.round(x * 10) / 10) + " Mbps"); }],
          ['inet_down', 'Down', function (x) { return ("↓ " + (Math.round(x * 10) / 10) + " Mbps"); }],
          ['dph_total', 'Cost', function (x) { return ("$" + (Math.round(x * 1000) / 1000) + "/hr"); }],
          ['reliability2', 'R', function (x) { return 100 * Math.round(x * 10) / 10; }],
          // ['pcie_bw', 'PCIE_BW', x => Math.round(x * 10) / 10],
          ['duration', 'Max Days', function (x) { return Math.round(10 * (Math.round(x * 10) / 10) / (24.0*60.0*60.0)) / 10; }] ]},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
        var offer = ref.row;
return [(offer.actual_status === offer.intended_status)?_c('div',[_c('button',{staticClass:"btn btn-outline-danger btn-sm btn-block",on:{"click":function($event){return _vm.rent(offer.id)}}},[_vm._v("Rent")])]):_c('div',[_c('em',[_vm._v(_vm._s(offer.intended_status))])])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }