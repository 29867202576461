<template>
  <form @submit.prevent>
    <div class="input-group input-group-sm mb-2">
      <input type="text"
        placeholder="Filename"
        :value="name"
        class="form-control"
        v-on:input="$emit('name', i, $event.target.value)"
      />
      <div class="input-group-append">
        <span class="input-group-text">.{{ ext }}</span>
      </div>
    </div>
    <small class="text-muted" v-show="imageData.name !== `${name}.${ext}`">
      Original: {{ imageData.name }}
    </small>
    <div class="row mx-0">
      <div class="col-2 px-0 d-flex align-items-center">
        <button v-show="!url"
          class="btn btn-link btn-sm px-0 btn-block"
          style="text-decoration: none !important;"
          @click="$emit('remove', i)"
        >⨂</button>
      </div>
      <div class="col-10 px-0 d-flex align-items-center">
        <progress :value="completion * 100" max="100" class="w-100 mb-0" />
      </div>
    </div>
    <img class="preview" :src="url ? url : b64">
  </form>
</template>

<script>
function toBase64(imgData) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(imgData);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  props: {
    i: Number,
    imageData: File,
    completion: Number,
    name: String,
    ext: String,
    url: String,
  },
  data: () => ({
    b64: null,
  }),
  created() {
    toBase64(this.imageData).then((data) => {
      this.b64 = data;
    });
  }
}
</script>

<style lang="css">
.preview {
  width: 100%;
}
</style>
