import { Octokit } from '@octokit/rest';
import Zip from 'adm-zip';
import request from 'request';

const octokit = (auth) => new Octokit({ auth });
const owner = 'mythulu-software';
const repo = 'card-art-ai';
const per_page = 5;

export const workflows = {
  run: 'run-instance.yml',
  rent: 'rent-instance.yml',
  list: 'list-instances.yml',
  configure: 'configure-instance.yml',
  stop: 'stop-instance.yml',
  start: 'start-instance.yml',
  destroy: 'destroy-instance.yml',
  // find: 'find-instance.yml',
  // label: 'label-instance.yml',
};
export const jobs = {
  instances: 'instances',
};

export async function run(api_key, workflow_id, inputs) {
  const { data } = await octokit(api_key).rest.actions.createWorkflowDispatch({
    owner,
    repo,
    workflow_id,
    ref: 'main',
    inputs,
  });
  return data;
}

export async function getInstances(api_key, run_id) {
  const {
    data: { artifacts },
} = await octokit(api_key).rest.actions.listWorkflowRunArtifacts({
    owner,
    repo,
    run_id,
  });
  const promises = artifacts.map(
    async ({ id: artifact_id }) => await octokit(api_key).rest.actions.downloadArtifact({
      owner,
      repo,
      artifact_id,
      archive_format: 'zip',  // required
    })
  );
  const [ { url } ] = await Promise.all(promises);

  const instances = await new Promise((resolve, reject) => {
    request.get(
      { url: url, encoding: null },
      // eslint-disable-next-list no-unused-vars
      async (err, res, body) => {
        if (err) {
          console.error(err);
          reject(err);
        }
        const zip = Zip(await body);
        const instancesJsonText = zip.readAsText(zip.getEntries()[0]);
        const { instances } = JSON.parse(instancesJsonText);
        resolve(instances);
      }
    );
  });
  return instances;
}

export async function getRuns(api_key, workflow_id) {
  const {
    data: { workflow_runs },
  } = await octokit(api_key).rest.actions.listWorkflowRunsForRepo({
    owner,
    repo,
    workflow_id,
    per_page,
  });
  return workflow_runs.map(({
    id, workflow_id, created_at, name, status, conclusion, rerun_url,
    logs_url,
  }) => ({
    id, workflow_id, created_at, name, status, conclusion, rerun_url,
    logs_url,
  }));
}

export async function logs(api_key, job_id, quantity) {
  const { data } = await octokit(api_key).rest.actions.downloadJobLogsForWorkflowRun({
    owner,
    repo,
    job_id,
    per_page: quantity || per_page,
  });
  console.log(data);
  return data;
}
